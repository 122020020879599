.App {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
}
.comingSoon h1 {
  font-size: 5em;
  font-weight: 100;
  margin-bottom: 0px;
}